import React from "react"
import { graphql, StaticQuery, Link } from 'gatsby'
import Img from "gatsby-image"
import styles from "./act.module.css"

import Accordion from "./accordion"
import BazeStamp from "../svgs/baze-stamp.svg"
import AndreasKummertStamp from "../svgs/andreas-kummert-stamp.svg"
import CarolineChevinStamp from "../svgs/caroline-chevin-stamp.svg"

class Act extends React.Component {


  mainActImage(data, stamp) {
    var image = data.allFile.edges.filter(img => img.node.name === this.props.id)[0]
    if (image) {
      return (
        <div className={styles.actImageWrapper}>
          {stamp}
          <Img
            className={styles.actImage}
            fixed={image.node.childImageSharp.fixed}
            objectFit="cover"
            objectPosition="50% 50%"
            alt=""
          />
        </div>
      )
    }
    return <div></div>
  }

  supportActImage(data) {
    var image = data.allFile.edges.filter(img => img.node.name === (this.props.id + "-support"))[0]
    if (image) {
      return (
        <div className={styles.actImageWrapper}>
          <Img
            className={styles.actImage}
            fixed={image.node.childImageSharp.fixed}
            objectFit="cover"
            objectPosition="50% 50%"
            alt=""
          />
        </div>
      )
    }
    return <div></div>
  }

  supportAct() {
    if (this.props.support) {
      return <>
        <h3>{this.props.support}</h3>
        <span className={styles.support}>Support Act</span>
      </>
    }
  }

  timeTable() {
    if (this.props.past == "true") {

    } else {
      return (<table className={styles.time}>
        <tbody>
          <tr><td>18:00</td><td>Apéro <br />{this.props.support}</td></tr>
          <tr><td>19:00-22:00</td><td>Hauptgang, Dessert <br />{this.props.name}</td></tr>
        </tbody>
      </table>)
    }
  }
  cta() {
    if (this.props.past == "true") {
      if (this.props.impressions != "false") {
        return <div className={styles.ctas}><Link className={styles.link} to={this.props.id}>→ Impressionen</Link></div>
      }
    } else {
      let link = ""
      if (this.props.id === "caroline-chevin") {
        link = "https://eventfrog.ch/de/p/gruppen/max-cuap-6633279622748337060.html"
      }
      if (this.props.id === "baze") {
        link = "https://eventfrog.ch/de/p/konzert/pop-rock/max-cuap-baze-6689125372845388796.html"
      }

      return <div className={styles.ctas}>
        <a className={styles.link} target="_blank" href={link}>→ Tickets online kaufen</a>
        <Link className={styles.link} to="tickets">→ Infos zu den Tickets</Link>
      </div>
    }
  }

  render() {
    return <div id={this.props.id} className={styles.act}>
      <span className={styles.date}>{this.props.date}</span>

      <h2>{this.props.name}</h2>
      <span className={styles.main}>Main Act</span>
      {this.supportAct()}

      <StaticQuery
        query={graphql`
                    query {
                        allFile{
                          edges {
                            node {
                              name
                              childImageSharp {
                                fixed(width: 400) {
                                    ...GatsbyImageSharpFixed_withWebp
                                  }
                              }
                            }
                          }
                        }
                      }
                `}
        render={data => {
          var stamp
          if (this.props.id === "andreas-kummert") {
            stamp = <AndreasKummertStamp className={styles.kummertStamp}></AndreasKummertStamp>
          }
          if (this.props.id === "baze") {
            stamp = <BazeStamp className={styles.bazeStamp}></BazeStamp>
          }
          if (this.props.id === "caroline-chevin") {
            stamp = <CarolineChevinStamp className={styles.chevinStamp}></CarolineChevinStamp>
          }

          return <div>
            {this.mainActImage(data, stamp)}
            {this.timeTable()}

            <Accordion>
              {this.props.children}
              {this.supportActImage(data)}
            </Accordion>
            {this.cta()}


          </div>

        }}
      />

    </div>
  }
}

export default Act