import React from "react"

import SEO from "../components/seo"
import Act from "../components/act"
import styles from "./events.module.css"

import Stamp1SVG from "../svgs/baze-stamp.svg"
import Stamp3SVG from "../svgs/caroline-chevin-stamp.svg"

const Events = () => (
    <div className="content">
        <SEO title="Events" />
        <h1>Events</h1>

        <p className={"short"}>
            Bei uns gibt’s was auf die Ohren – und in den Magen
        </p>
        <div className={styles.overview}>
            <a href="#baze">
                <span className={styles.artist}>Baze und NacNecc</span>
                <span className={styles.overviewDate}>20.08.2020</span>
            </a>
        </div>
        <p className={"long"}>
            Unsere Konzerte verbinden. Wir kreieren einen Raum, in dem sich Menschen begegnen, gemeinsam essen, sich austauschen können; und als Sahnehäubchen einen Künstler in seiner reinsten Form erleben. Ehrlich, intim und fadägrad.
            Wenn ein Künstler unplugged auftritt, entblösst er mit viel Gefühl sein Innerstes, seine Seele. Innerhalb unserer speziellen und
            eher unkonventionellen Konzertreihe spielen etablierte Künstler unplugged im FrachtRaum. Während dem Apéro treten aufstrebende, lokale Bands aus der Region Thun auf.
            Das Konzert wird von einem mit viel Hingabe gestalteten Dinner begleitet. Wir kochen das Lieblingsgericht des Hauptacts, das Dessert gibt die Vorband vor. Wir bieten 120 Gästen ein Feuerwerk für die Sinne, mit viel Liebe fürs Detail. Events, die von Mainstream weit entfernt sind.    </p>

        <Act id="baze" name="Baze" support="NacNecc" date="20.08.2020" past="false" impressions="false">
            <h4>Baze</h4>
            <p>
                Er ist ein etablierter Berner Mundart-Rapper und Mitglied der Chlyklass-Crew, der auch Wurzel 5 und PVP angehören.  Andächtig, mit der ihn bezeichnenden, wortgewandten Detailverliebtheit setzt sich Baze mit dem Zentrum unserer Welt auseinander: DenMenschen, deren Gedanken- und Gefühlswelten. Auf unaufdringliche Art und Weise bildet Baze’ unverkennbare Stimme in seinen Liedern den Mittelpunkt, in welchen er teilsfederleicht, teils beklemmend nachdenklich über den Alltag sinniert. Baze ist der Closing Act der ersten Reihe von Max Cuap unplugged.
        </p>
            <h4>NacNecc</h4>
            <p>Mal elektronisch-wuchtig, mal akustisch-intim - der Berner Sänger, Songwriter und Beatbastler NacNecc bespielt eine grosse Bandbreite. Dabei sind sein Stil und seine markante Stimme unverwechselbar. Zusammen mit dem Produzenten und Multi-Instrumentalisten Matthias Urech bringt NacNecc seine Mundartsongs, die irgendwo zwischen Elektro, Pop und HipHop angesiedelt sind, auf die Bühne. Das kann laut, aber auch sehr leise sein, es kann nachdenklich machen oder zum Tanzen anregen. Vor allem aber macht seine Musik eines – sie begeistert.
        </p>
        </Act>
        <Act id="caroline-chevin" support="Another Me" name="Caroline Chevin" date="12.03.2020" past="true" impressions="false">
            <h4>Caroline Chevin</h4>
            <p>
                Für einige Zeit kehrte sie der Schweiz den Rücken, um in Neuseeland zu leben. Jetzt ist dieSoulsängerin zurück und nach einem schweren Schicksalsschlag sind ihre Songs stärkerund ausdrucksstärker denn je. Caroline ist eine der ganz Grossen, sie hat die Bühne bereitsmit Eric Clapton und Bryan Adams geteilt. Sie passt mit ihrer fokussierten und doch sehrzugänglichen Art perfekt zu Max. Sie ist genau wie er eine Weltenbummlerin, die es liebt, alleEcken der Erde in ihrer Vielfalt zu erleben.
        </p>
            <h4>Another Me</h4>
            <p>
                Seit mittlerweile einem Jahrzehnt gibt es die beiden Leadsängerinnen der Band auf und wenn immer möglich auch neben der Bühne im Doppelpack. Die gemeinsam geschriebenen Songs lassen einen unweigerlich spüren, wie besonders die zwei harmonieren. Zusammen mit je einem älteren Geschwister spielen sie Akustik-Pop vom Feinsten.
        </p>

        </Act>

        <Act id="mudd" support="Phil Dankner" name="Dan Mudd" past="true">
            <h4>Dan Mudd</h4>
            <p>Das Ganze hört sich an wie eine musikalische Liebesgeschichte; Dan Mudd wurde von Dominik Liechti in Bern als Strassenmusiker gesehen und seitdem kam er nicht mehr los von ihm. Er erkannte in ihm einen der Besten seines Fachs. Wie das Leben so spielt, fanden die beiden Vollblutmusiker tatsächlich zusammen und stehen seitdem regelmässig gemeinsam auf der Bühne.</p>
            <h4>Phil Dankner</h4>
            <p>"Ein Maximum an Wohlgefühl, in Verbindung mit Souveränität und der Aesthetik unserer Zeit, sind meine Ziele und bestimmen mein Handeln."</p>
        </Act>
    </div >
)

export default Events