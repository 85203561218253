import React, { useState, useRef } from "react";
import styles from "./accordion.module.css"

class Accordion extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            extended: false
        }

        this.toggle = this.toggle.bind(this);
    }

    toggle(e){
        this.setState(
            {
                extended: !this.state.extended
            }
        )

        return false
    }

    classList() {
        if (this.state.extended) {
            return " " + styles.extended
        }
        return ""
    }

    content() {
        if (this.state.extended) {
            return "weniger"
        }
        return "mehr erfahren"
    }

    render() {
        return <div className={styles.accordion}>
            <div className={styles.content + this.classList()}>
                {this.props.children}
            </div>
            <a className={styles.button} href="javascript:void(0)" onClick={this.toggle}>{this.content()}</a>
        </div>
    }
}

export default Accordion